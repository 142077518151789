/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';


import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { handleCustomSelect } from './js/config/functions';

document.addEventListener("DOMContentLoaded", function () {

    // --- Menu window
    initMenuWindows();

    // --- Animations
    initAnimations();

    // --- Testimonies
    initTestimonies();

    // --- Carousels
    initCarousels();

    // --- Share buttons 
    initShareButtons();

    // --- Custom select 
    initCustomSelects();

    // --- Search 
    initSearch();

    // --- Popups
    initPopups();
});

const initMenuWindows = () => {

    const htmlElement = document.querySelector('html');
    const bodyElement = document.querySelector('body');
    const veilElement = document.querySelector('.veil');

    function openMenuWindow(element) {
        // Close other
        document.querySelectorAll('[data-opener].opened').forEach(openedElement => {
            openedElement.classList.remove('opened');
        });
        // Open window
        element.classList.add('opened');
        // Prevent scroll from body
        veilElement.classList.add('visible');
        bodyElement.classList.add('no-scroll');
        htmlElement.style.overflow = 'hidden'; // Fix for mobile

        if (element.dataset.anchorOpener !== undefined) {
            window.location.hash = element.dataset.anchorOpener;
        }
    }

    document.querySelectorAll('[data-opener]').forEach(element => {

        let closeElements = element.querySelectorAll('.js-close-window');
        closeElements.forEach(closeElement => {
            closeElement.addEventListener('click', function () {
                element.classList.remove('opened');
                veilElement.classList.remove('visible');
                bodyElement.classList.remove('no-scroll');
                htmlElement.style.removeProperty('overflow');

                if (element.dataset.anchorOpener !== undefined) {
                    history.pushState({}, "", window.location.href.replace(window.location.hash, ''))
                }
            });
        });

        let openerElements = document.querySelectorAll('.' + element.dataset.opener);
        openerElements.forEach(openerElement => {
            openerElement.addEventListener('click', function () {
                openMenuWindow(element);
            });
        });
    });

    document.querySelectorAll('[data-anchor-opener]').forEach(element => {
        if (element.dataset.anchorOpener !== '' && window.location.hash.substring(1) === element.dataset.anchorOpener) {
            openMenuWindow(element);
        }
        if (element.dataset.successParam) {
            window.location.hash = window.location.hash + '-' + element.dataset.successParam;
        }
    });

    veilElement.addEventListener('click', function () {
        document.querySelectorAll('[data-opener].opened').forEach(openedElement => {
            openedElement.classList.remove('opened');
        });
        veilElement.classList.remove('visible');
        bodyElement.classList.remove('no-scroll');
        htmlElement.style.removeProperty('overflow');

        if (window.location.hash !== '') {
            history.pushState({}, "", window.location.href.replace(window.location.hash, ''))
        }
    });
};

const initAnimations = () => {
    const animationThreshold = .3; // Intersection ratio
    const incrementAnimationDuration = 800; // Animation duration in ms

    const handleReveals = function (entries, observer) {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                entry.target.classList.add('visible');
                observer.unobserve(entry.target);
            }
        });
    }

    const handleIncrements = function (entries, observer) {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                observer.unobserve(entry.target);

                const originalValue = entry.target.innerHTML;
                const end = entry.target?.dataset?.incrementTo;

                if (!end) return;

                let startTimestamp = null;
                const step = (timestamp) => {
                    if (!startTimestamp) startTimestamp = timestamp;
                    const progress = Math.min((timestamp - startTimestamp) / incrementAnimationDuration, 1);
                    entry.target.innerHTML = Math.round(progress * end);
                    if (progress < 1) {
                        window.requestAnimationFrame(step);
                    } else {
                        entry.target.innerHTML = originalValue;
                    }
                };
                window.requestAnimationFrame(step);
            }
        });
    }

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: animationThreshold
    };

    const revealObserver = new IntersectionObserver(handleReveals, options);
    const reveals = document.querySelectorAll('.js-anim-reveal');
    reveals.forEach(target => {
        revealObserver.observe(target);
    });

    const incrementObserver = new IntersectionObserver(handleIncrements, options);
    const increments = document.querySelectorAll('.js-anim-increment');
    increments.forEach(target => {
        incrementObserver.observe(target);
    });
};

const initCarousels = () => {
    const carousels = document.querySelectorAll('.carousel');
    carousels.forEach(keyFiguresCarousel => {
        new Swiper(keyFiguresCarousel.querySelector('.swiper'), {
            modules: [Navigation],
            slidesPerView: 1.2,
            spaceBetween: 30,
            autoplay: false,
            loop: true,
            navigation: {
                nextEl: keyFiguresCarousel.querySelector('.carousel__controller .swiper-button-next'),
                prevEl: keyFiguresCarousel.querySelector('.carousel__controller .swiper-button-prev'),
            },
            // Responsive
            breakpoints: {
                992: {
                    slidesPerView: 1,
                    spaceBetween: 35,
                }
            }
        });
    });

    if (document.querySelector('.other-news__carousel')) {
        const newsCarousel = document.querySelector('.other-news__carousel');
        new Swiper(newsCarousel.querySelector('.swiper'), {
            modules: [Navigation],
            slidesPerView: 1.15,
            spaceBetween: 20,
            autoplay: false,
            loop: true,
            navigation: {
                nextEl: newsCarousel.querySelector('.carousel__controller .swiper-button-next'),
                prevEl: newsCarousel.querySelector('.carousel__controller .swiper-button-prev'),
            },
            // Responsive
            breakpoints: {
                992: {
                    slidesPerView: 3,
                    spaceBetween: 35,
                }
            }
        });
    }
}

const initTestimonies = () => {
    const testimoniesCarousels = document.querySelectorAll('.testimonies__carousel');

    testimoniesCarousels.forEach(testimoniesCarousel => {
        new Swiper(testimoniesCarousel.querySelector('.swiper'), {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 20,
            autoplay: false,
            loop: true,
            navigation: {
                nextEl: testimoniesCarousel.querySelector('.carousel__controller .swiper-button-next'),
                prevEl: testimoniesCarousel.querySelector('.carousel__controller .swiper-button-prev'),
            }
        });
    });
}

const initShareButtons = () => {
    // --- Share buttons
    const shareByLinks = document.querySelectorAll('.share-by-link');
    Array.from(shareByLinks).forEach(element => {
        element.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();

            var copyText = element.dataset.href;
            navigator.clipboard.writeText(copyText);

            const popoverMessage = element.closest('.popover').querySelector('.popover__message');
            popoverMessage.classList.add('active');

            setTimeout(() => {
                popoverMessage.classList.remove('active');
            }, 2000);
        })
    });

    const shareByPopup = document.querySelectorAll('.share-by-popup');
    Array.from(shareByPopup).forEach(element => {
        element.addEventListener('click', function (event) {

            event.preventDefault();
            event.stopPropagation();

            window.open(element.getAttribute('href'), 'pop', 'popup');
        })
    });
};

const initCustomSelects = () => {
    document.querySelectorAll('.custom-select').forEach(customSelect => {
        handleCustomSelect(customSelect, (customSelect.dataset.submitOnChange || 'false') !== 'false', customSelect.closest('form'));
    });
}

const initSearch = () => {

    function search (searchField) {

        let path = searchField.closest('form').getAttribute('action');
        let suggestionField = searchField.parentNode.querySelector('.search-form__suggestions');

        if(searchField.value) {

            const searchValue = searchField.value.replaceAll(' ', '+');
            const data = {'termes': searchValue};

            fetch(path, {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })
            .then(response => {
                if(response.status == 200) {
                    return response.json();
                }
            })
            .then(data => {

                suggestionField.innerHTML = '';

                if(!data.length) { return; }

                let suggestions = '';
                data.forEach(element => {
                    suggestions += '<li title="' + element.replaceAll('<mark>', '').replaceAll('</mark>', '') + '">' + element + '</li>';
                });

                // Add item with events
                let itemBuilder = document.createElement('div');
                itemBuilder.innerHTML = '<ul>' + suggestions + '</ul>';
                let suggestionsUl = itemBuilder.firstChild;
                suggestionField.appendChild(suggestionsUl);

                // Add event
                let suggestionsLi = suggestionsUl.querySelectorAll('li');
                suggestionsLi.forEach(suggestionLi => {
                    suggestionLi.addEventListener('click', function () {
                        let valueWithMark = this.innerHTML;
                        valueWithMark = valueWithMark.replaceAll('<mark>', '');
                        const valueWithoutMark = valueWithMark.replaceAll('</mark>', '');
                        searchField.value = valueWithoutMark;
                        searchField.closest('form').submit();
                    });
                });

            });
        } else {
            suggestionField.innerHTML = '';
        }
    }

    const searchField = document.querySelector('.search-form input[name="termes"]');
    if(searchField) {
        searchField.addEventListener('keyup', () => {
            search(searchField);
        });
    }

    // Clear search form
    document.querySelectorAll('.search-form').forEach(searchForm => {
        let clearFormButton = searchForm.querySelector('.search-form__clear');
        let searchField = searchForm.querySelector('.search-form__field');
        if(clearFormButton && searchField) {
            clearFormButton.addEventListener('click', function () {
                searchField.value = '';
                searchField.dispatchEvent(new Event('keyup'));
                searchField.focus();

                if (searchForm.dataset.submitOnClear !== undefined) {
                    searchForm.submit();
                }
            })
        }
    });

    // Autofocus
    document.querySelectorAll('.js-search-opener').forEach(searchOpener => {
        searchOpener.addEventListener('click', function () {
            if(searchField) {
                searchField.focus();
            }
        })
    });
};

const initPopups = () => {
    document.querySelectorAll('.popup-modal').forEach(modal => {
        modal.addEventListener('click', event => {
            if (event.target == modal || event.target.classList.contains('close')) {
                modal.classList.remove('active');
            }
        });
    });
};