
// --- Common functions
export const handleCustomSelect = (selectWrapper, submitOnChange = false, form = null) => {
    const selectElement = selectWrapper.querySelector('select');
    const selectedDiv = document.createElement('div');
    selectedDiv.className = `${selectElement.id} select-selected`;
    selectedDiv.textContent = selectElement.options[selectElement.selectedIndex].textContent;
    selectWrapper.appendChild(selectedDiv);

    const optionsDiv = document.createElement('div');
    optionsDiv.className = 'select-items select-hide';
    for (let i = 0; i < selectElement.length; i++) {
        const option = selectElement.options[i];
        if (option.value === '') {
            continue;
        }

        const optionDiv = document.createElement('div');
        optionDiv.textContent = option.textContent;

        if (selectedDiv.innerText === option.textContent || option.selected) {
            optionDiv.classList.add('same-as-selected');
        }

        if (option.dataset.counter !== undefined) {
            const counterEl = document.createElement('span');
            counterEl.className = 'counter';
            counterEl.textContent = option.dataset.counter;
            optionDiv.appendChild(counterEl);
        }

        optionDiv.addEventListener('click', function (e) {
            if (this.classList.contains('same-as-selected')) {
                // On vide le select si on clique sur l'option déjà sélectionnée
                select(selectWrapper, selectedDiv, 0, selectElement.options[0].textContent);
            } else {
                select(selectWrapper, selectedDiv, i, option.textContent);
                this.classList.add('same-as-selected');
            }

            selectedDiv.click();
            selectElement.dispatchEvent(new Event('change'));
        });
        optionsDiv.appendChild(optionDiv);
    }
    selectWrapper.appendChild(optionsDiv);

    selectedDiv.addEventListener('click', function (e) {
        e.stopPropagation();
        optionsDiv.classList.toggle('select-hide');
        selectedDiv.classList.toggle('select-arrow-active');
    });

    // On ferme le select si on clique en dehors 
    document.addEventListener('click', e => {
        if (selectedDiv.classList.contains('select-arrow-active')) {
            e.stopPropagation();
            optionsDiv.classList.toggle('select-hide');
            selectedDiv.classList.remove('select-arrow-active')
        }
    });

    if (submitOnChange && form) {
        // On change - Valider le formulaire si on est pas dans un menu ouvert (mobile):
        selectElement.addEventListener('change', e => {
            const filterMenu = form.parentElement.parentElement;
            if (filterMenu && !filterMenu.classList.contains('opened')) {
                form.submit();
            }
        });
    }
}

const select = (selectWrapper, selectedDiv, selectedIndex, selectedText) => {
    document.querySelectorAll('.same-as-selected').forEach(el => el.classList.remove('same-as-selected'));
    const selectElement = selectWrapper.querySelector('select');
    selectElement.selectedIndex = selectedIndex;
    selectedDiv.textContent = selectedText;
    if (selectedIndex === 0) {
        selectWrapper.classList.remove('selected');
    } else if(!selectWrapper.classList.contains('selected')) {
        selectWrapper.classList.add('selected');
    }
}